import { VideoSrc } from 'prop-types'
const VideoElement = ({VideoSrc}) => {
 
  return (
    <div className='video-element'>
     <video className='video' width="100%" height="100%" controls autoPlay >
        <source src={VideoSrc} type="video/mp4"/>
        <source src={VideoSrc} type="video/ogg"/>
      Your browser does not support the video tag.
      </video>
    </div>
  )
}

export default VideoElement