import './App.css';
import weblogo from './logo-red.png';
import React, { useState } from 'react';
import VideoElement from './VideoElement';
import Heading from './Heading';
import ButtonArrow from './ButtonArrow';
import WelcomeIntro from './WelcomeIntro';
import ExitPage from './ExitPage';
import placeholder from './placeholder-video.mp4';



function App() {
  // handling logging in
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginFailed, setLoginFailed] = useState(false);
  const [loginMsg, setLoginMsg] = useState('');
  const [valuePw, setValuePw] = useState('');
  const [valueUn, setValueUn] = useState('');
  const [loginBtn, setLoginBtn] = useState('Login');

 

  const handleChangePw = (event) => {
    setPassword(event.target.value);
    setValuePw(event.target.value);
    
  };
  const handleChangeUn = (event) => {
    setUsername(event.target.value);
    setValueUn(event.target.value);
  };
  const authLogin = ()=> {
    let correctUsername;
    let correctPassword;
    let correctUserDetails = false;
    if(username === process.env.REACT_APP_USERNAME) {
      correctUsername = true;
    } else {
      correctUsername = false;
    }
    if(password === process.env.REACT_APP_PASSWORD) {
      correctPassword = true;
    } else {
      correctPassword = false;
    }
    if(correctPassword === true && correctUsername === true) {
      correctUserDetails = true;
    }
    if(correctUserDetails === true){
      setLoginBtn('Logging in...')
      setTimeout(()=>{
        setCount(count + 1);
        setValuePw("");
      setValueUn("");
      setLoginBtn('Login')
      },1000)
      
    } else {
      setLoginMsg('Login Failed, please try again');
      setLoginFailed(true);
      setValuePw("");
      setValueUn("");
      setTimeout(()=>{
        setLoginFailed(false)
      },3000)

    }
  }
  const handleClick = () => {
    authLogin()
  };
// handling page movement

  const [count, setCount] = useState(-1);

  const incrementCount=()=>{
    setCount(count + 1);
  }
  const decrementCount=()=>{
    if(!(count <= 0)){
      setCount(count -1);
    }
    
  }
  const logout = ()=> {
    setCount(-1);
  }
  
  return (
    <>
  
    <div className="app">
      <header className="app-header">
        <img className="logo" alt="logo" src={weblogo}/>
      </header> 
      <body className="app-body">
        <div className='body-container'>
          <div className={count === 6 || count === 4? 'video-container-calendly video-container intake-form-container' : 'video-container'}>
              { count === 1 ? <Heading HeadingCount = {count} VideoSubject='Introduction'  />: ''}
              { count === 2 ? <Heading HeadingCount = {count} VideoSubject='Contract' />: ''}
              { count === 3 ? <Heading HeadingCount = {count}  VideoSubject='Access' />: ''}
              { count === 4 ? 
              <Heading HeadingCount = {count} VideoSubject='Intake Form'/>: ''}
              { count === 5 ? <Heading HeadingCount = {count} VideoSubject='Strategy Session'/>: ''}
              { count === 6 ? <Heading HeadingCount = {count} VideoSubject='Final Words'/>: ''}
              
              {count === -1 ? 
              <div className="login-page">
              <div className="login-form">
                  <div className="login-text-container">
                      <h2 className="login-heading">Let's begin!</h2>
                      <p className="login-info">Please use the credentials already provided to you </p>
                  </div>
                  <div className="login-inputs-container">
                      <div className="login-inputs-inner-container">
                          <input id="login-username" className="login-inputs" placeholder="User Name" type="text" value={valueUn} onChange={handleChangeUn}/>
                          <input id="login-password" className="login-inputs" placeholder="Password" type="password" value={valuePw}
                          onChange={handleChangePw}/>
          
                          <button onClick={handleClick} className="login-button slide"><span className="text">{loginBtn}</span></button>
                          <div class={loginFailed === true ? 'login-failed login-message': 'login-pending login-message'}>{loginMsg}</div>

                      </div>
                  </div>
              </div>    
          </div>
              : ''} 
            {count === 0 ? <WelcomeIntro /> : ''}
            {count === 1 ? <VideoElement VideoSrc={placeholder}/> : ''}
            {count === 2 ? <VideoElement VideoSrc={placeholder}/> : ''}
            {count === 3 ? <VideoElement VideoSrc={placeholder}/> : ''}
            {count === 4 ? 
            <div>
              <VideoElement VideoSrc={placeholder}/>
             <h4>Intake Form: <a href='https://forms.gle/35i1DuP4QRFrjNT56' target="_blank">Client Intake Form</a> </h4>
            </div>
            : ''}
            {count === 5 ?
            <div>
            <VideoElement VideoSrc={placeholder}/>
            <h4>Book here: <a href='https://calendly.com/shopifox/shopify' target="_blank">Strategy Call</a> </h4>
           </div>
          : ''}
            {count === 6 ? <VideoElement VideoSrc={placeholder}/> : ''}
            {count === 7 ? <ExitPage /> : ''}
          </div>
          { count  < 7 && count !== -1 ?
            <button className= {count >= 7 ? '' : 'button-next slide'} onClick={incrementCount}><span className='text'>Next <ButtonArrow/></span></button>
            : ''
          }
          {
            count === 7 ? 
            <button onClick={logout} className="button-next slide button-hide"  ><span className='text'>Log out <ButtonArrow/></span></button> : ''
          }
      </div>
      </body>
    </div>
    </>
  );
}

export default App;
