const ExitPage = () => {
  return (
    <div className="welcome-intro-container">
    <h1 className="welcome-message">
        <span className="second brandname">Congra<span className="brand-style">t</span>ulations!</span>
        <span className="first"></span>
        <span className="third">Your Onboarding is complete!</span>
        <span className="first">We're looking forward to seeing you at the strategy call!</span>
    </h1>
</div>
  )
}

export default ExitPage