
const WelcomeIntro = () => {
    return (
      <div className="welcome-intro-container">
              <h1 className="welcome-message">
                  <span className="first">Welcome to the</span>
                  <span className="second"></span>
                  <span className="second brandname">Wild L<span className="brand-style">a</span>ngosta Client</span>
                  <span className="third">Onboarding!</span>
              </h1>
      </div>
    )
  }
  
  export default WelcomeIntro