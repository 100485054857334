import {HeadingCount, VideoSubject} from 'prop-types'
const Heading = ({HeadingCount, VideoSubject}) => {
  return (
    <div className='heading-container'>
        <h3 className='heading-count'>   
            Part {HeadingCount}:  <span className='video-subject'> {VideoSubject}</span>
        </h3>
        
    </div>
  )
}

export default Heading